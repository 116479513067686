@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .MiniScrollbar::-webkit-scrollbar-button {
    @apply hidden;
  }

  .MiniScrollbar::-webkit-scrollbar {
    @apply h-2 w-2;
  }

  .MiniScrollbar::-webkit-scrollbar-track {
    @apply rounded-[4px] bg-transparent;
  }

  .MiniScrollbar::-webkit-scrollbar-thumb {
    @apply rounded-[4px] bg-gray-200;
  }

  .MiniScrollbar::-webkit-scrollbar-thumb:hover {
    @apply rounded-[4px] bg-gray-300;
  }

  body.dark .MiniScrollbar::-webkit-scrollbar-thumb {
    @apply rounded-[4px] bg-slate-500;
  }

  body.dark .MiniScrollbar::-webkit-scrollbar-thumb:hover {
    @apply rounded-[4px] bg-slate-400;
  }
}

/* Chrome, Safari, Edge, Opera */
.input-no-arrows::-webkit-outer-spin-button,
.input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-no-arrows[type='number'] {
  -moz-appearance: textfield;
}
