.auto-scroll-content {
  display: inline-block;
  white-space: nowrap;
}

.scrolling .auto-scroll-content {
  animation: scrollText 30s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(10%);
  }
}
